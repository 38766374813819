<template>
	<div class="container px-6 mx-auto grid dark:bg-gray-900">
		<div class="grid grid-cols-3 gap-1">
			
			<div>
				<h2
					class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200 text-left"
				>
					Agregar Asientos Contables
				</h2>
			</div>
			
			<div class="grid ">
				<div v-if="objTransaction.transactions.length > 0" class="">
					<h4 
						class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200"
					>
						<span v-if="result === 0" class="text-green-600">Monto de descuadre: {{result}}</span> 
						<span v-else class="text-red-600">Monto de descuadre: {{result}}</span> 
					</h4>
				</div>
			</div>
			
			<div class="py-4 w-full text-right">
				<div>
					<div class="inline-flex">
						<div class="py-1 mr-2">
							<input type="date" v-model="transactionDate" class="border-2 w-40 border-gray-300 rounded">
						</div>
						<div v-if="!saving">
							<button type="button" @click="saveTransaction" class="px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg active:bg-blue-500 hover:bg-green-700 focus:outline-none focus:shadow-outline-purple">
								<i class="icon-floppy" aria-hidden="true"></i>
								Guardar
							</button>
						</div>
						<div v-else>
							
							<button type="button" class="px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-300 border border-transparent rounded-lg active:bg-blue-500 focus:outline-none focus:shadow-outline-purple">
								<i class="icon-arrows-cw animate-pulse text-green-600 mr-1" aria-hidden="true"></i>
								Guardar
							</button>
						</div>
					</div>
				</div>
				<div>
					Es asiento inicial: 
					<select v-model="objTransaction.is_initial_balance" class="border-2 border-gray-300 rounded mt-4">
						<option value="0">No</option>
						<option value="1">Si</option>
					</select>
				</div>
				<div>
					Tipo auxiliar: 
					<select v-model="auxiliaryType" @change="getAuxiliary()" class="border-2 border-gray-300 rounded mt-4">
						<option value="contract">Contrato</option>
						<option value="staff">Personal</option>
					</select>
				</div>
			</div>
				
		</div>
		
		
	
		<!-- <Banner /> -->
			<div class="w-full overflow-hidden rounded-lg shadow-xs ">
				<div class="w-full overflow-x-auto">
					<table class="w-full whitespace-no-wrap">
						<thead v-if="objTransaction.transactions.length > 0">
							<tr
								class="text-xs font-semibold tracking-wide  text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
							>
								<!-- <th class="px-2 py-1 w-36">Fecha</th> -->
								<th class="px-2 py-1 w-24">Fecha</th>
								<th class="px-2 py-1 w-64">Cuenta</th>
								<th class="px-2 py-1 w-60">Referencia</th>
								<th class="px-2 py-1 ">Descripcion</th>
								<th class="px-2 py-1 text-right w-24">Debe</th>
								<th class="px-2 py-1 text-right w-24">Haber</th>
								<th class="px-2 py-1 text-right w-24">opciones</th>
							</tr>
						</thead>
						<tbody v-if="objTransaction.transactions.length > 0"
							class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
						>

							<tr v-for="(item, index) in objTransaction.transactions" :key="index" class="text-gray-700 dark:text-gray-400">
								
								<td class="px-2 py-1 text-sm text-left w-56">
									
									<button v-if="editMode === index" v-on:click="updateRowTransactionSave(index, item)" class="px-2 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-400 border border-transparent rounded-l-md active:bg-blue-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-purple"><i class="icon-ok" aria-hidden="true"></i></button>
									<input v-if="editMode === index" type="date"  v-model="item.transactionDate" class="border-2 px-2 py-1  border-gray-300 rounded-r-md md:w-50">
									<span v-else> {{item.transactionDate}} </span>
								</td>
								<td class="px-2 py-1 text-sm text-left w-56">
									{{item.accountCode}}
								</td>
								<td class="px-2 py-1 text-xs  w-28">
									<div v-if="editMode === index" class="flex block-inline">
										<button v-on:click="updateRowTransactionSave(index, item)" class="px-2 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-400 border border-transparent rounded-l-md active:bg-blue-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-purple"><i class="icon-ok" aria-hidden="true"></i></button>
										<select v-model="item.reference_type" class="border-2 border-gray-300 rounded">
											<option value=""></option>
											<option value="FAC">Factura</option>
											<option value="CT">Contrato</option>
											<option value="DP">Deposito</option>
											<option value="CH">Cheque</option>
											<option value="TRA">Tranferencia</option>
											<option value="NC">Nota Credito</option>
											<option value="ND">Nota Debito</option>
											<option value="TC">Tarjeta Credito</option>
											<option value="TD">Tarjeta Debito</option>
											<option value="ZE">Zelle</option>
											<option value="REI">Reintegro</option>
											<option value="EFE">Efectivo</option>
											<option value="PRES">Prestamo</option>
											<option value="WTra">Wire Transfer</option>
										</select>
										<input type="text"  v-model="item.reference" class="border-2 border-gray-300 rounded w-full" placeholder="Referencia">
										<input type="text"  v-model="item.auxiliary" class="border-2 border-gray-300 rounded w-full" placeholder="Aux">
									</div>
									<span v-else>{{item.reference_type}}:	{{item.reference}} - {{item.auxiliary}} </span>
								</td>
								<td class="px-2 py-1 text-xs text-left w-60">
									
									<button v-if="editMode === index" v-on:click="updateRowTransactionSave(index, item)" class="px-2 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-400 border border-transparent rounded-l-md active:bg-blue-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-purple"><i class="icon-ok" aria-hidden="true"></i></button>
									<input v-if="editMode === index" type="text"  v-model="item.description" class="border-2 px-2 py-1  border-gray-300 rounded-r-md md:w-50">
									<span v-else> {{item.description}} </span>
								</td>
								<td class="px-2 py-1 text-sm text-right">
									<div v-if="editMode === index" class="flex block-inline">
										<button v-on:click="updateRowTransactionSave(index, item)" class="px-2 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-400 border border-transparent rounded-l-md active:bg-blue-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-purple"><i class="icon-ok" aria-hidden="true"></i></button>
										<input type="text"  v-model="item.debit" class="border-2 px-2 py-1  border-gray-300 rounded-r-md w-28">
									</div>
									<span v-else> {{item.debit > 0 ? item.debit : ' '}}</span>
								</td>
								<td class="px-2 py-1 text-sm text-right">
									<div v-if="editMode === index" class="flex block-inline">
										<button v-on:click="updateRowTransactionSave(index, item)" class="px-2 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-400 border border-transparent rounded-l-md active:bg-blue-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-purple"><i class="icon-ok" aria-hidden="true"></i></button>
										<input type="text"  v-model="item.credit" class="border-2 px-2 py-1  border-gray-300 rounded-r-md w-28">
									</div>	
									<span v-else> {{item.credit > 0 ? item.credit : ' '}}</span>
								</td>
								<td class="px-2 py-1 text-sm text-right">
									<button type="button" @click="updateRowTransactionEdit(index)" class="px-2 py-1 mr-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline-purple">
										<i class="icon-edit" aria-hidden="true"></i>
									</button>
									<button type="button" @click="deleteTransaction(index)" class="px-2 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-blue-500 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple">
										<i class="icon-trash" aria-hidden="true"></i>
									</button>
								</td>
							</tr>

						</tbody>
					</table>
				</div>
				
			</div>
			<form @submit.prevent="updateTransaction" autocomplete="off">
				<div class="w-full overflow-hidden rounded-lg shadow-xs mt-4">
					<div class="w-full overflow-x-auto h-screen">
						<table class="w-full whitespace-no-wrap">
							<tbody
								class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
							>
								<tr class="text-gray-700 dark:text-gray-400">
									
									<td  class="px-2 py-1 text-sm" colspan="6">
										<div class="w-full flex">
											<div class="text-sm md:w-1/3">
												<Multiselect 
													v-model="account" 
													tag-placeholder="Add" 
													placeholder="Cuenta" 
													label="name" track-by="code" 
													:options="objAccount" 
													:multiple="false" 
													:taggable="true" 
													@tag="addTag"
												></Multiselect>
											</div>
											<div class="text-sm md:w-1/3">
												<Multiselect 
													v-model="auxiliary" 
													tag-placeholder="Add" 
													placeholder="Aux" 
													label="name" track-by="code" 
													:options="ObjAuxiliary" 
													:multiple="false" 
													:taggable="true" 
													@tag="addTag"
												></Multiselect>
											</div>
											<div class="text-sm md:w-1/6 p-2">
												<select v-model="reference_type" class="border-2 border-gray-300 rounded w-full">
													<option value=""></option>
													<option value="FAC">Factura</option>
													<option value="CT">Contrato</option>
													<option value="DP">Deposito</option>
													<option value="CH">Cheque</option>
													<option value="TRA">Tranferencia</option>
													<option value="NC">Nota Credito</option>
													<option value="ND">Nota Debito</option>
													<option value="TC">Tarjeta Credito</option>
													<option value="TD">Tarjeta Debito</option>
													<option value="ZE">Zelle</option>
													<option value="REI">Reintegro</option>
													<option value="EFE">Efectivo</option>
													<option value="PRES">Prestamo</option>
													<option value="WTra">Wire Transfer</option>
												</select>
											</div>
											<div class="text-sm md:w-1/6 p-2">
												<input type="text"  v-model="reference" class="border-2 border-gray-300 rounded w-full" placeholder="Referencia">
											</div>
											
										</div>
									</td>
									
								</tr>
								<tr class="text-gray-700 dark:text-gray-400">
									
									<td colspan="6" class="">
										<div class="w-full flex p-2 space-x-5">
											<div class="w-full">
												<input type="text"  v-model="description" class="border-2 border-gray-300 rounded w-full" placeholder="Descripcion">
											</div>
											<div class="w-full space-x-5">
												
												<input type="text" v-model="debit" class="border-2 border-gray-300 rounded text-right w-24" placeholder="Debito">												
												
												<input type="text" v-model="credit" class="border-2 border-gray-300 rounded text-right w-24" placeholder="Credito">
												
											</div>
											<div class="w-full text-right">
												<button type="submit" class="px-2 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline-purple">
													<i class="icon-plus-circled" aria-hidden="true"></i>
												</button>
											</div>
										</div>
									</td>
								</tr>

							</tbody>
						</table>
						<div class="text-right">
							<input type="file" id="media" @change="select_file" name="media" multiple>
							<!-- <input type="file"  multiple> -->
						</div>
						
					</div>
				</div>
			</form>
		
	</div>
</template>

<script>
import Banner from '@/components/util/Banner.vue'
import ComboBasic from '@/components/util/ComboBoxBasic.vue'
import FielText from '@/components/util/FielText.vue'
import Btn from '@/components/util/btnBasic.vue'
import Multiselect from 'vue-multiselect'
import Axios from 'axios'
import { mapState, mapMutations } from 'vuex'
import hotkeys from 'hotkeys-js';
	export default {
		name: 'AccTransactions',
		components: {
			Banner,
			ComboBasic,
			FielText,
			Btn,
			Multiselect,
		},
		props: {},
		data() {
			return {
				editMode: -1,
				loading: false,
				URL: process.env.VUE_APP_URL_ACC,
				URLAdm: process.env.VUE_APP_URL,
				error: false,
				account:'',
				transactionDate: '',
				description:'',
				reference:'',
				reference_type:'',
				auxiliary: null,
				debit: 0,
				credit: 0,
				result: 0,
				is_initial_balance: 0,
				auxiliary_type: 'accounting',
				objAccount: [],
				ObjAuxiliary: [],
				saving: false,
				objTransaction:{
					"is_initial_balance": 0,
					// "company": 20,
					// "year": 2023,
					"file": [],
					"transactions":[
						
					]
				},
				updateRowTransaction:{
					"description":'',
				},
				auxiliaryType: 'contract',
			}
		},
		computed: {
			...mapState(['user','year', 'accTemp', 'accTransResul','access_token']),	
					
		},
		mounted() {
			this.getAccTransaction();
			this.getAuxiliary();
			hotkeys('ctrl+g', (event, handler) => {
				event.preventDefault();
				console.log('Ctrl + q pressed');
				this.saveTransaction()
				// Aquí puedes ejecutar la acción que deseas realizar con el atajo de teclado
			});
			hotkeys('ctrl+s', (event, handler) => {
				event.preventDefault();
				console.log('Ctrl + S pressed');
				// Aquí puedes ejecutar la acción que deseas realizar con el atajo de teclado
			});
		},
		beforeUnmount() {
			hotkeys.unbind('ctrl+q');
			hotkeys.unbind('ctrl+s');
		},
		methods: {
			select_file(event){
				console.log(event.target.files)
				this.objTransaction.file = event.target.files
			},
			...mapMutations(["changeAccTemp", "deleteTransactionTemp"]),
			addTag (newTag) {
                const tag = {
                    name: newTag,
                    code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
                }
                this.options.push(tag)
                this.transaction.push(tag)
            },
			async getAuxiliary() { //
				if (this.auxiliaryType === 'contract') {
					const URL = `${this.URLAdm}v1/contract`
					const fetchConfig = {
						headers: {
							Accept : 'application/json',
							"Content-Type": 'application/json',
							Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
						}
					}
					let params = {
						companyId: this.user.companyId
					}
					// console.log(params)
					// try {
					let rsAccount = await Axios.post(URL, params, fetchConfig)
					console.log(rsAccount)
					// return
					const result = rsAccount.data;
					// console.log(result)
					this.ObjAuxiliary = result.map(item => {
						// console.log(item.client.clientName)
							return { name: item.contractNumber + ' - ' + item.siteAddress, code: item.contractNumber}
						// return { text: item.accountName, value: item.id}
					})
				}
				if (this.auxiliaryType === 'staff') {
					const URL = `${this.URLAdm}staff-all`
					const fetchConfig = {
						headers: {
							Accept : 'application/json',
							"Content-Type": 'application/json',
							Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
						}
					}

					let rsAccount = await Axios.get(`${URL}/${this.user.companyId}`, fetchConfig)
					// console.log(rsAccount)
					// return
					const result = rsAccount.data.staff;
					// console.log(result)
					// return
					this.ObjAuxiliary = result.map(item => {
						// console.log(item.client.clientName)
							return { name: item.staffCode  + ' - ' + item.shortName, code: item.staffCode}
						// return { text: item.accountName, value: item.id}
					})
				}
				
				
			},
			async saveTransaction() {
				this.objTransaction.company= this.user.companyId;
				this.objTransaction.year = this.year;
				this.objTransaction.is_initial_balance = parseInt(this.objTransaction.is_initial_balance)
				
				if (this.objTransaction.is_initial_balance === 1) {
					let confirmar = confirm("Desea Guardar el asiento como saldo inicial?");
					// console.log(confirmar)
					if (confirmar === false) {
						return
					}
				}
				// return
				this.saving = !this.saving
				const URL = `${this.URL}storeTransaction`
				const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json',
                    }
				}
				if (this.result != 0 || this.objTransaction.transactions.length  == 0) {
					alert("Verifique el descuadre o que existan asientos contables")
					this.saving = !this.saving
					return 'empy'
				}
				console.log(this.objTransaction)
				// return
				try {
					let accTrans = await Axios.post(URL, this.objTransaction, fetchConfig)
					// console.log('accTrans')
					// console.log(accTrans)
					// console.log(this.objTransaction)
					if (this.objTransaction.file.length > 0) {
						// guardar archivos
						let f = new FormData();
	
						//Agregar todos los archivos seleccionados al FormData
						for (let index = 0; index < this.objTransaction.file.length; index++) {
							console.log(this.objTransaction.file[index])
							f.append("media[]", this.objTransaction.file[index]); //https://stackoverflow.com/a/14908250
						}
						f.append("collection", 'general');
						let files = await Axios.post(`${this.URL}addMediaToAccountingEntry/${accTrans.data.data.accounting_entry_id}`, f, fetchConfig)
						// console.log('files')
						// console.log(files)
						this.objTransaction.file = []
					}
					
					alert("Guardado")
					this.objTransaction.is_initial_balance = 0
					this.objTransaction.transactions = []
					this.saving = !this.saving
					localStorage.removeItem('accTransResul')
				} catch (error) {
					Object.keys(error).forEach(key => {
						console.log(error[key])
					})
						console.log(error.response)
					if (error.message == 'Request failed with status code 422') {
						this.saving = false
						alert(error.response.data.data.message)
					} 
				}

				
			},
			updateRowTransactionEdit(index){
				
				// console.log(index)
				this.editMode = index
				// console.log(this.objTransaction.transactions[index])
			},
			updateRowTransactionSave(index, item){
				this.editMode = -1
				const DEBIT = this.objTransaction.transactions.map(item => parseFloat(item.debit)).reduce((prev, curr) => prev + curr, 0);
				console.log(DEBIT);
				const CREDIT = this.objTransaction.transactions.map(item => parseFloat(item.credit)).reduce((prev, curr) => prev + curr, 0);
				console.log(CREDIT);
				this.result = DEBIT - CREDIT
				this.changeAccTemp([{"transaction": this.objTransaction.transactions , "result_D_C": this.result}])
			},
			updateTransaction() {
				// console.log(this.objTransaction.transactions)
				console.log(this.auxiliary)
				if (this.auxiliary == null) {
					this.auxiliary = {code: ''}
				}
				if (this.transactionDate === '') {
					alert('Antes de agregar un asiento, por favor seleccione una fecha.')
					return
				}
				
				this.objTransaction.transactions.push({
					account_id: this.account.code,
					accountCode: this.account.name,
					reference: this.reference,
					description: this.description,
					transactionDate: this.transactionDate,
					user_id: this.user.userId,
					reference_type: this.reference_type,
					auxiliary_type: this.auxiliary_type,
					auxiliary: this.auxiliary.code,
					company_id: this.user.companyId,
					debit: parseFloat(this.debit),
					credit: parseFloat(this.credit),
				});
				
				// console.log(this.objTransaction.transactions);
				// return
				const DEBIT = this.objTransaction.transactions.map(item =>  parseFloat(item.debit)).reduce((prev, curr) => prev + curr, 0);
				// console.log(DEBIT);
				const CREDIT = this.objTransaction.transactions.map(item =>  parseFloat(item.credit)).reduce((prev, curr) => prev + curr, 0);
				// console.log(CREDIT);

				this.result = DEBIT - CREDIT
				this.changeAccTemp([{"transaction": this.objTransaction.transactions , "result_D_C": this.result}])
				this.account = ''
				this.reference = ''
				this.description = ''
				this.debit = 0
				this.credit = 0
			},
			async getAccTransaction() {
				console.log(this.accTransResul)
				if (this.accTransResul.length > 0) { // se utiliza para saber si hay transacciones en el storage y posteriormente cargarlas
					this.objTransaction.transactions = this.accTransResul[0].transaction
					this.result = this.accTransResul[0].result_D_C
					console.log('entro')
				}
				const URL = `${this.URL}showAllCompanyAccountsFlatMode`
				const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json'
                        // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
                    }
				}
				let params = {
					company_id: this.user.companyId,
					company: this.user.companyId,
					year: this.year
				}
				
				// try {
					let rsAccount = await Axios.post(URL, params, fetchConfig)
					
					this.objAccount = rsAccount.data.data.map(item => {
						
						return { name: item.accountCode + ' - ' + item.accountName, code: item.id}
						
					})
					// console.log('this.objAccount')
					// console.log(this.objAccount)
			},
			deleteTransaction(index){
				console.log(index)
				this.objTransaction.transactions.splice(index, 1)
				console.log(this.objTransaction)
				const DEBIT = this.objTransaction.transactions.map(item =>  parseFloat(item.debit)).reduce((prev, curr) => prev + curr, 0);
				// console.log(DEBIT);
				const CREDIT = this.objTransaction.transactions.map(item =>  parseFloat(item.credit)).reduce((prev, curr) => prev + curr, 0);
				// console.log(CREDIT);

				this.result = DEBIT - CREDIT
				this.changeAccTemp([{"transaction": this.objTransaction.transactions , "result_D_C": this.result}])
				console.log(this.result)
			}
		},
		
		watch: {

		},
			
	}
</script>

<style >

</style>